import React from 'react';
import { Trans } from '@lingui/react';
import bottomArrowStyle from '../../styles/bottomArrowStyle';
import Carousel from '../Carousel';
import Section from '../Grid/Section';
import Title from '../Title/Title';
import ClientItem from './ClientItem';

const Clients = ({ data }) => {
  return (
    <Section id={'clients'} css={ theme => bottomArrowStyle(theme, 'brown') } flexDirection={ 'column' } alignItems={ 'center' }>
      <Title><Trans>Clientes</Trans></Title>
      <Carousel mobilePerRow={ 2 } mobilePerColumn={ 2 } perRow={ 5 } perColumn={ 2 } spaceBetween={ 25 }>
        { data.edges.map(({ node }, key) => (
          <ClientItem key={ key } title={ node.title } logo={ node.acf.logo.url } site={ node.acf.site } />
        )) }
      </Carousel>
    </Section>
  );
};

export default Clients;
