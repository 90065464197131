import { Trans } from '@lingui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box, Button, Text } from '@rebass/emotion';
import { rem } from '../../utils';

const Logo = styled.img`
  max-width: ${ rem(150) };
  width: 100%;
  border-radius: 100%;
  overflow: hidden;
  
  img {
    border-radius: 100%;
  }
`;

const Title = styled.h3`
  width: 100%;
  margin-bottom: ${ rem(15) };
  color: ${ ({ theme }) => theme.colors.white };
  font-size: ${ ({ theme }) => rem(theme.fontSizes[3]) };
  text-transform: uppercase;
`;

const Subtitle = styled.h4`
  width: 100%;
  margin-bottom: ${ rem(25) };
  color: ${ ({ theme }) => theme.colors.white };
  font-size: ${ ({ theme }) => rem(theme.fontSizes[2]) };
`;

const Description = styled.p`
  width: 100%;
  margin-bottom: ${ rem(50) };
  color: ${ ({ theme }) => theme.colors.white };
  font-size: ${ ({ theme }) => rem(theme.fontSizes[0]) };
`;

const CaseItem = ({ logo, title, subtitle, site, description, channels, targe, mainResults, handleOpenCase, ...otherProps }) => {
  return (
    <Box { ...otherProps }>
      <Logo src={ logo } alt={title} />
      <Title>{ (""+title).replace(/&#8211;/g, "-") }</Title>
      <Subtitle>{ subtitle }</Subtitle>
      <Description>{ description }</Description>
      <Button as={ 'button' }
        onClick={ () => handleOpenCase({
          logo,
          title,
          subtitle,
          site,
          description,
          channels,
          targe,
          mainResults
        }) }
        width={ [1 / 2, 1 / 3] }
        variant={ 'outline' }>
        <Trans>Veja o case</Trans>
      </Button>
    </Box>
  );
};

CaseItem.propTypes = {
  logo: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  site: PropTypes.string,
  description: PropTypes.string,
  channels: PropTypes.array,
  targe: PropTypes.string,
  mainResults: PropTypes.string,
  handleOpenCase: PropTypes.func.isRequired,
};

export default CaseItem;
