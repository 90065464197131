import React from 'react';
import { Text } from '@rebass/emotion';
import { css } from '@emotion/core';
import { rem } from '../../utils';

const titleStyle = css`
  text-align: center;
`;

const Title = props => {
  return (
    <Text as={ 'h2' }
      width={ 1 }
          mt={0}
      mb={ [ rem(50) ] }
      color={ 'green' }
      fontSize={ [8, 6] }
      css={ titleStyle }
      { ...props } />
  );
};

export default Title;
