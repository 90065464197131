import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Trans } from '@lingui/react';
import { Box, Flex } from '@rebass/emotion';
import bottomArrowStyle from '../../styles/bottomArrowStyle';
import { rem } from '../../utils';
import Section from '../Grid/Section';
import Title from '../Title/Title';
import Container from '../Grid/Container';
import { backgroundStyle } from '../Header/styles';
import PeopleItem from './PeopleItem';

const People = ({ data }) => {
  return (
    <Section id={ 'team' } css={ theme => [backgroundStyle(theme), bottomArrowStyle(theme, 'green')] }>
      <Container flexDirection={ 'column' } alignItems={ 'center' }>
        <Title><Trans>Quem faz</Trans></Title>
        <Flex width={ 1 } flexWrap={ 'nowrap' } flexDirection={ ['column', 'row'] }>
          {
            data.edges.map(({ node }, key) => (
              <PeopleItem key={ key } name={ node.title } position={ node.acf.cargo } image={ node.acf.foto.url }>
                { node.acf.descricao }
              </PeopleItem>
            ))
          }
        </Flex>
      </Container>
    </Section>
  );
};

export default People;
