import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import Swiper from 'react-id-swiper';
import { Pagination } from 'swiper/dist/js/swiper.esm';
import 'react-id-swiper/src/styles/css/swiper.css';
import { rem } from '../../utils';
import carouselArrow from '../../images/carouselArrow.svg';

const bulletActiveBg = bulletTheme => {
  switch (bulletTheme) {
  case 'grayLight':
    return '#257e7a';
  case 'brownWhite':
    return '#6e442d';
  default:
    return '#4aeddf';
  }
};

const Container = styled.div`
  width: calc(100vw - 50px);
  position: relative;
  padding-left: calc((100vw - 50px - 1140px) / 2);
  padding-right: calc((100vw - 50px - 1140px) / 2);
  box-sizing: border-box;
  
  .swiper-container {
    width: 100%;
    padding-bottom: 75px;
  
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet {
        background-color: ${ ({ bulletTheme }) => bulletTheme === 'grayLight' ? '#a2674a' : 'white' };
        opacity: 1;
  
        &-active {
          background-color: ${ ({ bulletTheme }) => bulletActiveBg(bulletTheme) };
        }
      }
    }
  }
`;

const baseNavigation = (theme, backgroundColor) => css`
  position: absolute;
  display: none;
  width: ${ rem(20) };
  height: ${ rem(200) };
  top: calc(50% - 37.5px);
  transform: translateY(-50%);
  background: url(${ carouselArrow }) no-repeat center center;
  background-color: ${ backgroundColor === 'brownWhite' ? theme.colors.brown : theme.colors[backgroundColor] };
  background-size: 11px 13px;
  color: white;
  border: none;
  transition: 300ms background-color ease-in-out;
  
  @media(min-width: 1024px) {
    display: block;
  }
  
  &:hover {
    background-color: ${ theme.colors.green };
  }
`;

const NextNavigation = styled.button`
  ${ ({ theme, backgroundColor }) => baseNavigation(theme, backgroundColor) }
  transform: translateY(-50%) rotate(180deg);
  right: -25px;
`;

const PrevNavigation = styled.button`
  ${ ({ theme, backgroundColor }) => baseNavigation(theme, backgroundColor) }
  left: -25px;
`;

class Carousel extends Component {
  constructor (props) {
    super(props);

    this.setInstance = this.setInstance.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  render () {
    const { children, perRow, perColumn, mobilePerColumn, mobilePerRow, spaceBetween, theme } = this.props;

    const minimumForNavigation = perRow * perColumn;
    const totalItems = Children.count(children);
    const hasNavigation = totalItems > minimumForNavigation;

    let swiperConfig = {
      spaceBetween: spaceBetween,
      slidesPerView: perRow,
      slidesPerColumn: perColumn,
      slidesPerColumnFill: 'row',
      slidesPerGroup: perRow,
      breakpoints: {
        1024: {
          slidesPerView: mobilePerRow,
          slidesPerColumn: mobilePerColumn,
          slidesPerGroup: mobilePerRow,
          slidesPerColumnFill: 'row',
        },
      }
    };

    if (hasNavigation) {
      swiperConfig = {
        ...swiperConfig,
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
      };
    }

    return (
      <Container width={1} bulletTheme={theme}>
        { hasNavigation && (<PrevNavigation backgroundColor={ theme } onClick={ this.handlePrev } />) }
        <Swiper getSwiper={ this.setInstance } { ...swiperConfig }>
          { children }
        </Swiper>
        { hasNavigation && (<NextNavigation backgroundColor={ theme } onClick={ this.handleNext } />) }

      </Container>
    );
  }

  setInstance (instance) {
    this.swiper = instance;
  }

  handleNext () {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  }

  handlePrev () {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  }
}

Carousel.defaultProps = {
  theme: 'grayLight',
  perRow: 1,
  perColumn: 1,
  mobilePerRow: 1,
  mobilePerColumn: 1,
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
  perRow: PropTypes.number,
  perColumn: PropTypes.number,
  mobilePerRow: PropTypes.number,
  mobilePerColumn: PropTypes.number,
  spaceBetween: PropTypes.number,
};

export default Carousel;
