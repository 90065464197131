import { Trans } from '@lingui/react';
import React, { Component } from 'react';
import { Box, Link } from '@rebass/emotion';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import bottomArrowStyle from '../../styles/bottomArrowStyle';
import { rem } from '../../utils';
import Carousel from '../Carousel';
import Container from '../Grid/Container';
import Section from '../Grid/Section';
import { backgroundStyle } from '../Header/styles';
import Title from '../Title/Title';
import CaseItem from './CaseItem';
import CaseModal from './CaseModal';
import downloadPdfIcon from '../../images/downloadPdfIcon.png';

const DownloadLink = styled(Link)`
  text-transform: uppercase;
  letter-spacing: ${ rem(4) };
  text-align: center;
  margin-bottom: ${ rem(70) };
  color: ${ ({ theme }) => theme.colors.white };
  font-weight: bold;
  position: relative;
  
  &:hover:after {
    max-width: 100%;
    left: 0;
  }
  
  &:after {
    content: '';
    width: 100%;
    max-width: 0;
    height: 3px;
    background-color: ${ ({ theme }) => theme.colors.green };
    position: absolute;
    display: block;
    bottom: -7px;
    left: 50%;
    transition: max-width 300ms ease-in-out, left 300ms ease-in-out;
  }
  
  &:before {
    content: '';
    width: ${ rem(17) };
    height: ${ rem(15) };
    background-image: url(${ downloadPdfIcon });
    display: inline-block;
    margin-right: ${ rem(10) };
  }
`;

class Cases extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isModalOpen: false,
      modalData: {}
    };

    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  render () {
    const { portfolio } = this.props;
    const { isModalOpen, modalData } = this.state;
    let pdfLink = '';

    if (portfolio &&
      portfolio.acf &&
      portfolio.acf.arquivo &&
      portfolio.acf.arquivo.url
    ) {
      pdfLink = portfolio.acf.arquivo.url;
    }

    return (
      <Section id={ 'cases' } css={ theme => [backgroundStyle(theme), bottomArrowStyle(theme, 'green') ] } pt={ rem(30) }>
        <Container pb={ rem(100) } flexDirection={ 'column' } alignItems={ 'center' }>
          <Title css={ css`margin-bottom: ${ rem(25) };` }><Trans>Cases</Trans></Title>
          <DownloadLink href={ pdfLink } target={'_blank'}><Trans>Baixe o pdf completo</Trans></DownloadLink>
          <Carousel mobilePerRow={ 1 } perRow={ 2 } spaceBetween={ 60 } theme={'brown'}>
            { this.props.data.edges.map(({ node }, key) => (
              <CaseItem title={ node.title }
                subtitle={ node.acf.sub }
                site={ node.acf.site }
                description={ node.acf.descricao }
                channels={ node.acf.canais }
                targe={ node.acf.targe }
                mainResults={ node.acf.principais_resultados }
                handleOpenCase={ this._openModal }
                logo={ node.acf.logo && node.acf.logo.url ? node.acf.logo.url : ( key === 0 ? 'https://admin.ptahx.com/wp-content/uploads/2019/04/12_genevalive.jpg' : 'https://admin.ptahx.com/wp-content/uploads/2019/04/08_swiss.jpg' ) }
                key={ key }/>
            )) }
          </Carousel>
          <CaseModal open={ isModalOpen } onClose={ this._closeModal } { ...modalData } />
        </Container>
      </Section>
    );
  }

  _openModal (modalData) {
    this.setState({
      isModalOpen: true,
      modalData,
    });
  }

  _closeModal () {
    this.setState({
      isModalOpen: false,
    });
  }
}

export default Cases;
