import { Trans } from '@lingui/react';
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Link, Flex } from '@rebass/emotion';
import Modal from 'react-responsive-modal';
import theme from '../../styles/theme';
import { rem } from '../../utils';
import iconMenuClose from '../../images/iconMenuClose.svg';

const Container = styled.div`
  display: flex;
  max-width: ${ rem(1140) }};
  width: 100%;
  flex-direction: column;
  
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Title = styled.h3`
  width: 100%;
  margin-top: 0;
  margin-bottom: ${ rem(15) };
  font-size: ${ ({ theme }) => rem(theme.fontSizes[3]) };
  text-transform: uppercase;
`;

const Subtitle = styled.h4`
  width: 100%;
  margin-bottom: ${ rem(35) };
  font-size: ${ ({ theme }) => rem(theme.fontSizes[2]) };
  
  @media(min-width: 1024px) {
    margin-bottom: ${ rem(70) };
  }
`;

const Section = styled.div`
  display: ${ ({ inline }) => inline ? 'inline-flex' : 'flex' };
  flex-direction: ${ ({ inline }) => inline ? 'row' : 'column' };
  width: 100%;
  margin-bottom: ${ rem(31) };
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.h5`
  font-size: ${ ({ theme }) => rem(theme.fontSizes[0]) };
  margin: 0;
  margin-right: ${ rem(5) };
  margin-bottom: 5px;
`;

const SectionText = styled.p`
  font-size: ${ ({ theme }) => rem(theme.fontSizes[0]) };
  margin: 0;
  margin-bottom: 5px;
  
  & > p {
    margin-top: 0;
  }
`;

const SectionLink = styled(Link)`
  color: ${ ({ theme }) => theme.colors.green };
`;

const modalStyles = {
  modal: {
    width: 'calc(100vw - 4.8rem)',
    maxWidth: '71.25rem',
    position: 'relative',
    paddingTop: 56,
    paddingRight: 36,
    paddingBottom: 36,
    paddingLeft: 36,
  },
  closeButton: {
    top: 0,
    right: 0,
    width: 56,
    height: 56,
    backgroundColor: theme.colors.green,
    backgroundImage: `url(${ iconMenuClose })`,
    backgroundSize: '30px 30px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  closeIcon: {
    display: 'none'
  }
};

const CaseModal = ({ open, onClose, logo, title, subtitle, site, description, channels, targe, mainResults }) => {
  const applicableChannels = channels ? channels.filter(channel => channel.canal && channel.url) : [];

  return (
    <Modal open={ open } styles={ modalStyles } center onClose={ onClose }>
      <Container flexDirection={ 'row' }>
        <Box width={ [1 / 2, 1 / 5] }>
          <img src={ logo } alt={title} />
        </Box>
        <Box width={ [1, 4 / 5] } ml={ [0, 36] } mt={ [36, 0] }>
          <Title>{ (""+title).replace(/&#8211;/g, "-") }</Title>
          <Subtitle>{ subtitle }</Subtitle>
          <Flex flexDirection={ [ 'column', 'row' ] }>
            <Box width={ [1, 1 / 2] } mr={ [0, 36] } mb={ [ 36, 0] }>
              { site ? (
                <Section inline>
                  <SectionTitle><Trans>Site</Trans>:</SectionTitle>
                  <SectionText as={ 'a' } href={ site } target={ '_blank' }>{ site }</SectionText>
                </Section>
              ) : null }
              { (applicableChannels && applicableChannels.length) ? (
                <Section>
                  <SectionTitle><Trans>Canais</Trans>:</SectionTitle>
                  { channels.map((channel, key) => <SectionText key={ key }>{ channel.canal }: <SectionLink href={ channel.url } target={ '_blank' }>{ channel.url }</SectionLink></SectionText>) }
                </Section>
              ) : null }
              { targe ? (
                <Section inline>
                  <SectionTitle><Trans>Targe</Trans>:</SectionTitle>
                  <SectionText>{ targe }</SectionText>
                </Section>
              ) : null }
              { description ? (
                <Section>
                  <SectionTitle><Trans>Contexto geral</Trans>:</SectionTitle>
                  <SectionText>{ description }</SectionText>
                </Section>
              ) : null }
            </Box>
            <Box width={ [1, 1 / 2] }>
              { mainResults ? (
                <Section>
                  <SectionTitle><Trans>Principais resultados</Trans>:</SectionTitle>
                  <SectionText as={ 'div' } dangerouslySetInnerHTML={ { __html: mainResults } } />
                </Section>
              ) : null }
            </Box>
          </Flex>
        </Box>
      </Container>
    </Modal>
  );
};

CaseModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  logo: PropTypes.object,
  title: PropTypes.string,
  site: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  channels: PropTypes.array,
  targe: PropTypes.string,
  mainResults: PropTypes.string,
};

export default CaseModal;
