import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@rebass/emotion';
import { rem } from '../../utils';

const imageStyle = css`
  max-width: ${ rem(250) };
  width: 100%;
  filter: grayscale(100%);
  transition: 300ms filter ease-in-out;
  will-change: filter;
  
  &:hover {
    filter: none;
  }
`;

const ClientItem = ({ logo, title, site, ...otherProps }) => {
  return (
    <Box { ...otherProps }>
      <Link width={ 1 } href={ site } target={'_blank'}>
        <img src={ logo } css={ imageStyle } alt={ title } />
      </Link>
    </Box>
  );
};

ClientItem.propTypes = {
  logo: PropTypes.object.isRequired,
  title: PropTypes.string,
  site: PropTypes.string,
};

export default ClientItem;
