import React from 'react';
import { css } from '@emotion/core';
import { Flex, Box, Text, Button, Image } from '@rebass/emotion';
import homeClip from '../../images/homeClip.svg';
import greenArrow from '../../images/greenArrow.svg';
import brownPattern from '../../images/brownPattern.png';
import bottomArrowStyle from '../../styles/bottomArrowStyle';
import { rem } from '../../utils';

const containerStyles = css`
  position: relative;
  min-height: 100vh;
  
  .slider-list {
    cursor: default !important;
  }
`;

const slideStyle = css`
  min-height: 100vh;
  background-size: cover;
  
  @media(min-width: 1024px) {
    background-attachment: fixed;
  }
`;

const contentBoxStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: white;
  
  @media(min-width: 1024px) {
    background-attachment: fixed;
  }
  
  h2 {
    text-transform: uppercase;
  }
`;

const cropClipStyle = css`
  position: absolute;
  bottom: -5px;
  left: 0;
  line-height: 0;
  background-image: url(${ homeClip });
  background-size: 100% 100%;
  padding-bottom: 8.4375%;
  
  @media(min-width: 1024px) {
      bottom: -5%;
    }
  
  &:after {
    bottom: 0;
    top: -15px;    
    @media(min-width: 1024px) {
      top: 20%;
    }
  }
`;

const Home = ({ data }) => {
  return (
    <Flex width={ 1 } css={ containerStyles }>
      {
        data.edges.map(({ node }, key) => (

          <Box key={key} width={ 1 } css={ [slideStyle, css`background-image: url(${ node.acf.imagem_de_fundo.url });`] }>
            <Box width={1} css={ contentBoxStyle }>
              <Text mb={ rem(40) } mt={ 0 } width={ [2 / 3, 3 / 7] } as={ 'h2' } fontSize={ [5, 6] } fontFamily={ 'sans' }>{ node.title }</Text>
              <Text mb={ rem(40) } mt={ 0 } width={ [2 / 3, 3 / 7] } as={ 'p' } fontSize={ [3, 4] } fontFamily={ 'sans' }>{ node.acf.subtitulo }</Text>
              { node.acf.texto_do_botao && (<Button as={ 'a' } href={ node.acf.link_do_botao } width={ [2 / 3, 1 / 7] } variant={ 'outline' }>{ node.acf.texto_do_botao }</Button>) }
            </Box>
          </Box>
        ))
      }
      <Box width={ 1 } css={ theme => [bottomArrowStyle(theme, 'green'), cropClipStyle] } />
    </Flex>
  );
};

export default Home;
