import { css } from '@emotion/core';
import { Flex, Text } from '@rebass/emotion';
import React from 'react';
import PropTypes from 'prop-types';
import { rem } from '../../utils';

const itemStyle = css`
  text-align: center;
`;

const imageStyle = css`
  max-width: ${ rem(220) };
  width: 100%;
  filter: grayscale(100%) sepia(60%);
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: ${ rem(30) };
  
  img {
    border-radius: 100%;
  }
`;

const nameStyle = css`
  text-transform: uppercase;
`;

const PeopleItem = props => {
  const { children, name, position, image } = props;
  return (
    <Flex width={ [1, 1 / 3] } mr={ [0, 40] } mb={ [40, 40] } flexDirection={ 'column' } alignItems={ 'center' } css={itemStyle}>
      <img src={ image } css={ imageStyle } />
      <Text as={ 'h4' } mt={ 0 } mb={ rem(5) } color={ 'white' } fontSize={ 2 } css={ nameStyle }>{ name }</Text>
      <Text as={ 'h5' } mt={ 0 } mb={ rem(30) } color={ 'white' } fontSize={ 2 }>{ position }</Text>
      <Text as={ 'p' } mt={ 0 } mb={ 0 } color={ 'white' } fontSize={ 0 }>
        { children }
      </Text>
    </Flex>
  );
};

PeopleItem.propTypes = {
  children: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default PeopleItem;
