import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box, Image, Flex } from '@rebass/emotion';
import { css } from '@emotion/core';
import bottomArrowStyle from '../../styles/bottomArrowStyle';
import { rem } from '../../utils';
import Section from '../Grid/Section';
import Title from '../Title/Title';
import Container from '../Grid/Container';
import aboutIllustration from '../../images/aboutIllustration.png';

const subtitleStyle = css`
  text-align: center;
`;

const paragraphStyle = css`
  @media (min-width: 1024px) {
    column-count: 1;
    column-gap: ${ rem(30) };
    column-fill: auto;
  }
  
  p {
    margin: ${ rem(30) } 0;
    break-inside: auto;
    
    &:first-child {
      margin-top: 0;
    }
  }
`;

const About = ({ title, subtitle, content, image }) => {
  return (
    <Section id={ 'about' } css={ theme => bottomArrowStyle(theme, 'brown') }>
      <Container pb={ rem(100) } flexDirection={ 'column' } alignItems={ 'center' } >
        <Title>{ title }</Title>
        <Text as={ 'h3' } mt={0} mb={ rem(50) } fontSize={ [2, 3] } width={ [1, 1 / 2] } css={ subtitleStyle }>{ subtitle }</Text>
        <Box mb={ rem(70) } css={ paragraphStyle } dangerouslySetInnerHTML={ { __html: content } } />
        <Box>
          <img src={ image } style={ { maxWidth: '100%' } } />
        </Box>
      </Container>
    </Section>
  );
};

About.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.object,
};

export default About;
