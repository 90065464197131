import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/emotion';
import React from 'react';
import { rem } from '../../utils';

const imageStyle = css`
  max-width: ${ rem(220) };
  width: 100%;
  filter: grayscale(100%) sepia(60%);
  border-radius: 100%;
  overflow: hidden;
  
  img {
    border-radius: 100%;
  }
`;

const Quote = styled.p`
  margin-top: 0;
  margin-bottom: 30px;
  color: ${ ({ theme }) => theme.colors.brown };
  font-size: ${ ({ theme }) => theme.fontSizes[0] }px;
  position: relative;
  padding-top: 55px;
  line-height: 1.5;
  
  &:before {
    content: '“';
    font-size: 154px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    position: absolute;
    line-height: 0.8;
    top: 0;
    left: -5px;
  }
`;

const Author = styled.h4`
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  color: ${ ({ theme }) => theme.colors.brown };
  font-size: ${ ({ theme }) => theme.fontSizes[3] }px;
`;

const Company = styled.h5`
  margin-top: 0;
  margin-bottom: 0;
  color: ${ ({ theme }) => theme.colors.brown };
  font-size: ${ ({ theme }) => theme.fontSizes[1] }px;
`;

const TestimonialItem = ({ text, photo, company, position, name, ...otherProps }) => {
  return (
    <Flex { ...otherProps } flexDirection={ [ 'column', 'row' ] } width={ 1 } px={ [0, '13%'] }>
      <Box width={ [1 / 2, 1 / 4] } mr={ [0, 50] } mb={ [ 50, 0 ] }>
        <img src={ photo } css={ imageStyle } />
      </Box>
      <Box width={ [ 1, 3 / 4 ] }>
        <Quote>
          { text }
        </Quote>
        <Author>{ name }</Author>
        <Company>{ position } - { company }</Company>
      </Box>
    </Flex>
  );
};

export default TestimonialItem;
