import { Trans } from '@lingui/react';
import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import Carousel from '../Carousel';
import Container from '../Grid/Container';
import Section from '../Grid/Section';
import Title from '../Title/Title';
import TestimonialItem from './TestimonialItem';

const Testimonial = ({ data }) => {
  return (
    <Section id={'testimonials'} css={ css`background-color: #f4dcd0;` }>
      <Container flexDirection={ 'column' } alignItems={ 'center' }>
        <Title><Trans>Depoimentos</Trans></Title>
        <Carousel mobilePerRow={1} perRow={ 1 } spaceBetween={ 60 } theme={'brownWhite'}>
          {
            data.edges.map(({ node }, key) => (
              <TestimonialItem key={ key }
                name={ node.title }
                company={ node.acf.empresa }
                position={ node.acf.cargo }
                text={ node.acf.texto }
                photo={ node.acf.foto.url }>
                { node.acf.descricao }
              </TestimonialItem>
            ))
          }
        </Carousel>
      </Container>
    </Section>
  );
};

Testimonial.propTypes = {

};

export default Testimonial;
