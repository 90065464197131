import React, { Component, Fragment } from 'react';
import { Trans, I18n } from '@lingui/react';
import styled from '@emotion/styled';
import { Box, Button, Flex } from '@rebass/emotion';
import { rem } from '../../utils';
import Container from '../Grid/Container';
import Section from '../Grid/Section';
import Title from '../Title/Title';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  background-color: ${ ({ theme }) => theme.colors.brownLigher };
  color: ${ ({ theme }) => theme.colors.brown };
  font-size: ${ ({ theme }) => rem(theme.fontSizes[0]) };
  border: none;
  padding: ${ rem(20) } ${ rem(15) };
  box-sizing: border-box;
  font-weight: bold;
  resize: none;
  
  &::placeholder {
    text-transform: uppercase;
    color: ${ ({ theme }) => theme.colors.brown };
    letter-spacing: 2px;
  }
`;
const InputContainer = styled.div`
  margin-bottom: ${ rem(36) };
  position: relative;
  
  &::after {
    width: calc(100% - ${ rem(30) });
    height: ${ rem(3) };
    content: '';
    display: block;
    position: absolute;
    left: ${ rem(15) };
    bottom: ${ rem(-3) };
    background-color: ${ ({ theme }) => theme.colors.brown };
  }
`;

const BrownButton = styled(Button)`
  align-self: flex-end;
  color: ${ ({ theme }) => theme.colors.brown };
  border-color: ${ ({ theme }) => theme.colors.brown };
  padding-left: ${ rem(40) };
  padding-right: ${ rem(40) };
  
  &:hover {
    background-color: ${ ({ theme }) => theme.colors.brown };
    color: ${ ({ theme }) => theme.colors.white };
  }
`;

const SubmittedMessage = styled.p`
  padding: ${ rem(15) };
  margin-top: ${ rem(36) };
  color:  ${ ({ theme }) => theme.colors.white };
  background-color:  ${ ({ theme, hasError }) => hasError ? 'red' : theme.colors.green };
  font-weight: bold;
  font-size: ${ ({ theme }) => rem(theme.fontSizes[0]) };
`;

const InfoBox = styled(Box)`
    font-size: ${ ({ theme }) => rem(theme.fontSizes[2]) };
    align-self: flex-end;
    
    @media(min-width: 1024px) {
      padding-bottom: 85px;
    }
    
    &, a {
      color:  ${ ({ theme }) => theme.colors.brown };
    }
    
    a {
      display: inline-block;
      margin-bottom: 10px;
      width: 100%;
    }
`;

class Contact extends Component {
  constructor (props) {
    super(props);

    this.state = {
      formState: 'UNSENT',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit (e) {
    const { formState, ...fields } = this.state;

    window.fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...fields })
    })
      .then(() => this.setState({
        formState: 'SUCCESS',
      }))
      .catch(error => this.setState({
        formState: 'ERROR',
      }));

    e.preventDefault();
  };

  handleChange (e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render () {
    return (
      <Section id={'contact'}>
        <Container flexDirection={ 'column' } alignItems={ 'center' }>
          <Title><Trans>Fale conosco</Trans></Title>
          <Flex width={ [1, 10 / 12] } flexDirection={['column', 'row']}>
            <Box width={ [1, 2 / 3] } mr={[ 0, rem(50)]} mb={[rem(50), 0]}>
              <Form onSubmit={this.handleSubmit} name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                <I18n>
                  { ({ i18n }) => (
                    <Fragment>
                      <InputContainer>
                        <Input name={ 'name' } onChange={this.handleChange} required placeholder={ i18n._(/* i18n */{ id: 'Nome' }) } />
                      </InputContainer>
                      <InputContainer>
                        <Input name={ 'email' } onChange={this.handleChange} required placeholder={ i18n._(/* i18n */{ id: 'Email' }) } />
                      </InputContainer>
                      <InputContainer>
                        <Input as={'textarea'} onChange={this.handleChange} required name={ 'message' } placeholder={ i18n._(/* i18n */{ id: 'Mensagem' }) } rows={ 10 } />
                      </InputContainer>
                    </Fragment>
                  ) }
                </I18n>
                <BrownButton as={ 'button' }
                  type={'submit'}
                  variant={ 'outline' }>
                  <Trans>Enviar</Trans>
                </BrownButton>
                <input type="hidden" name="form-name" value="contact" />
                { this.state.formState !== 'UNSENT' && (
                  <SubmittedMessage hasError={ this.state.formState === 'ERROR' }>
                    { this.state.formState === 'ERROR' ? (<Trans>Desculpe, houve um erro ao enviar a tua mensagem. Tente novamente.</Trans>) : (<Trans>Mensagem enviada com sucesso!</Trans>) }
                  </SubmittedMessage>
                ) }
              </Form>
            </Box>
            <InfoBox width={ [1, 1 / 3] }>
              <a href={ `mailto:${this.props.email}` }><strong>{ this.props.email }</strong></a>
              <a href={ `tel:${this.props.phone}` }>Tel. { this.props.phone }</a>
            </InfoBox>
          </Flex>
        </Container>
      </Section>
    );
  }
}

export default Contact;
