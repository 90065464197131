import { css } from '@emotion/core';
import { rem } from '../utils';
import brownArrow from '../images/brownArrow.svg';
import greenArrow from '../images/greenArrow.svg';

export default (theme, color) => css`
  position: relative;
  
  &:after {
    position: absolute;
    left: 50%;
    bottom: ${ rem(-46.666669) };
    width: ${ rem(35.3333351) };
    height: ${ rem(72.6666703) };
    margin-left: ${ rem(35.3333351 / -2) };
    background-image: url(${ color === 'green' ? greenArrow : brownArrow });
    content: " ";
    z-index: 1;
    background-size: 100% 100%;
    
    @media (min-width: 1024px) {
      bottom: ${ rem(-70) };
      width: ${ rem(53) };
      height: ${ rem(109) };
      margin-left: ${ rem(53 / -2) };
    }
  }
`;
