import React from 'react';
import { graphql } from 'gatsby';
import About from '../components/About';
import Cases from '../components/Cases';
import Clients from '../components/Clients';
import Contact from '../components/Contact';
import Home from '../components/Home';
import Layout from '../components/layout';
import People from '../components/People/People';
import Testimonial from '../components/Testimonial/Testimonial';

const IndexPage = ({ pageContext: { lang }, data }) => {
  const siteData = lang === 'pt' ? data.siteData : data.siteDataEn;
  const aboutData = lang === 'pt' ? data.about : data.aboutEn;

  return (
    <Layout siteData={ siteData } menu={ lang === 'pt' ? data.headerMenu : data.headerMenuEn } socialNetwork={ data.socialNetworkMenu } lang={lang}>
      <Home data={ lang === 'pt' ? data.home : data.homeEn } />
      { aboutData && (<About title={ aboutData.title } content={ aboutData.acf.conteudo } image={ aboutData.acf.imagem.url } subtitle={ aboutData.acf.subtitulo } />) }
      <People data={ lang === 'pt' ? data.people : data.peopleEn } />
      <Clients data={ lang === 'pt' ? data.clients : data.clientsEn } />
      <Cases data={ lang === 'pt' ? data.cases : data.casesEn } portfolio={ lang === 'pt' ? data.portfolio : data.portfolioEn } />
      <Testimonial data={ lang === 'pt' ? data.quotes : data.quotesEn } />
      <Contact phone={ siteData.acf.phone } email={ siteData.acf.email } />
    </Layout>
  );
};

export const query = graphql`
    query GetPage {
        siteData: wordpressPage(wordpress_id: { eq: 311 }) {
            acf {
                titulo,
                descricao,
                keywords,
                email,
                phone,
                endereco
            }
        }
        siteDataEn: wordpressWpPagesLangEn(wordpress_id: { eq: 334 }) {
            acf {
                titulo,
                descricao,
                keywords,
                email,
                phone,
                endereco
            }
        }
        headerMenu: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 2 }) {
            items {
                wordpress_id
                title
                url
                target
            }
        },
        headerMenuEn: wordpressWpApiMenus10LangEn {
            items {
                wordpress_id
                title
                url
                target
            }
        },
        socialNetworkMenu: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 13 }) {
            items {
                wordpress_id
                title
                url
                target
            }
        },
        home: allWordpressWpHomeSlides(filter: {wordpress_id: { ne: 257 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        subtitulo,
                        texto_do_botao
                        link_do_botao
                        imagem_de_fundo {
                            url
                        }
                    }
                }
            }
        }
        homeEn: allWordpressWpHomeSlidesLangEn(filter: {wordpress_id: { ne: 289 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        subtitulo,
                        texto_do_botao
                        link_do_botao
                        imagem_de_fundo {
                            url
                        }
                    }
                }
            }
        }
        about: wordpressPage(wordpress_id: { eq: 259 }) {
            title
            acf {
                subtitulo,
                conteudo,
                imagem {
                    url
                }
            }
        }
        aboutEn: wordpressWpPagesLangEn(wordpress_id: { eq: 273 }) {
            title
            acf {
                subtitulo,
                conteudo,
                imagem {
                    url
                }
            }
        }
        portfolio: wordpressPage(wordpress_id: { eq: 286 }) {
            acf {
                arquivo {
                    url
                }
            }
        }
        portfolioEn: wordpressWpPagesLangEn(wordpress_id: { eq: 303 }) {
            title
            acf {
                arquivo {
                    url
                }
            }
        }
        clients: allWordpressWpClients(filter: {wordpress_id: { ne: 298 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        site
                        logo {
                            url
                        }
                    }
                }
            }
        }
        clientsEn: allWordpressWpClientsLangEn(filter: {wordpress_id: { ne: 297 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        site
                        logo {
                            url
                        }
                    }
                }
            }
        }
        people: allWordpressWpPeople(filter: {wordpress_id: { ne: 296 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        cargo,
                        descricao
                        foto {
                            url
                        }
                    }
                }
            }
        }
        peopleEn: allWordpressWpPeopleLangEn(filter: {wordpress_id: { ne: 295 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        cargo,
                        descricao
                        foto {
                            url
                        }
                    }
                }
            }
        }
        cases: allWordpressWpCases(filter: {wordpress_id: { ne: 291 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        sub
                        descricao
                        site
                        canais {
                            canal
                            url
                        }
                        targe
                        principais_resultados
                    }
                }
            }
        }
        casesEn: allWordpressWpCasesLangEn(filter: {wordpress_id: { ne: 290 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        sub
                        descricao
                        site
                        canais {
                            canal
                            url
                        }
                        targe
                        principais_resultados
                        logo {
                            url
                        }
                    }
                }
            }
        }
        quotes: allWordpressWpQuotes(filter: {wordpress_id: { ne: 294 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        cargo
                        empresa
                        texto
                        foto {
                            url
                        }
                    }
                }
            }
        }
        quotesEn: allWordpressWpQuotesLangEn(filter: {wordpress_id: { ne: 292 }}, sort: { fields: [ menu_order ], order: [ASC] }) {
            edges {
                node {
                    title
                    acf {
                        cargo
                        empresa
                        texto
                        foto {
                            url
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
